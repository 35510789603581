import HighlightedWithShortList from '@/components/ArticleList/Templates/HighlightedWithShortList'
import { ArticleListProps } from '@/components/ArticleList/types'
import { Link } from '../Link/Link'
import ShortListOnly from './Templates/ShortListOnly'

const ArticleList = (props: ArticleListProps) => {
  const renderTemplates = () => {
    if (props.template === 'HIGHLIGHTED_WITH_SHORT_LIST') {
      return <HighlightedWithShortList {...props} />
    }
    if (props.template === 'SHORT_LIST_ONLY') {
      return <ShortListOnly {...props} />
    }
  }

  return (
    <div className="article-list mt-16">
      <div className="container-padded">{renderTemplates()}</div>
      {!props.hideNextButton && (
        <div className="text-center mt-12">
          <Link
            className="p-6 py-4 rounded font-semibold text-lg leading-5 cursor-pointer transition-all mt-4 bg-gradient-to-b from-lightSkyBlue to-purple hover:from-purple hover:to-lightSkyBlue w-full sm:w-auto"
            href="https://blog.popkulturalis.hu"
            target="_blank"
            title="Pokpulturális blog"
          >
            Tovább a blogra
          </Link>
        </div>
      )}
    </div>
  )
}

export default ArticleList
