import { Article } from '@/components/ArticleList/types'
import { Link } from '@/components/Link/Link'

export interface HighlightedItemProps {
  article: Article
}

const HighlightedItem = (props: HighlightedItemProps) => {
  return (
    <div className="article-list__highlighted-item grid grid-col-1 lg:grid-cols-2 mb-16">
      {props.article.imageUrl && (
        <div>
          <img
            className="w-full h-auto"
            src={props.article.imageUrl}
            alt={props.article.imageAlt || props.article.title}
          />
        </div>
      )}
      <div className="font-roboto mt-6 ml-0 lg:mt-0 lg:ml-6">
        <Link
          className="cursor-pointer"
          href={props.article.url}
          title={props.article.title}
          target="_blank"
        >
          <h1 className="font-roboto font-extrabold text-2xl md:text-3xl lg:text-4xl mb-4">
            {props.article.title}
          </h1>
          <p className="font-roboto font-normal text-base leading-6 mb-1">{props.article.lead}</p>
          <p className="mb-8 md:mb-12">
            <span className="font-roboto font-normal text-base text-lightGray">
              {props.article.date}
            </span>
          </p>
        </Link>
      </div>
    </div>
  )
}

export default HighlightedItem
