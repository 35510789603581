import { BaseSectionItem } from '@/types/homeContent.model'
import Video from '@/components/Video/Video'

export interface VideoBoxProps {
  title: string
  item: BaseSectionItem
}

const VideoBox = (props: VideoBoxProps) => {
  return (
    <div className="video-box pt-16 pb-48 flex justify-center">
      <div className=" w-full lg:w-3/4">
        <div className="container-padded">
          <h2 className="font-gilroy font-extrabold text-4xl sm:text-5xl text-center max-w-2xl ml-auto mr-auto mb-10">
            {props.title}
          </h2>
          <div className="video-box__video flex flex-col relative items-center min-w-full">
            <Video {...props.item} isVisible={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoBox
