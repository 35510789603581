import TopicItem, { TopicItemProps } from '@/components/TopicNavigation/TopicItem'

const TopicNavigation = () => {
  const topics = [
    {
      title: 'Zenekarok',
      url: '/eloado/1',
      icon: 'microphone-colored'
    },
    {
      title: 'Korszakok',
      url: '/korszak/1',
      icon: 'wave-colored'
    },
    {
      title: 'Koncertek',
      url: '/esemeny/1',
      icon: 'play-colored'
    },
    {
      title: 'Galériák',
      url: '/galeria/1',
      icon: 'image-colored'
    }
  ]

  return (
    <div className="topic-navigator text-center mt-12">
      <p className="font-gilroy text-2xl md:text-4xl font-extrabold mb-4">
        vagy csak böngéssz a múltban
      </p>
      <p className="font-roboto text-lg font-bold max-w-2xl ml-auto mr-auto">
        Keresd meg kedvenceidet, nézz vissza régi koncerteket, és tudj meg mindent a magyar
        könnyűzene ikonikus pillanatairól
      </p>
      <div className="topic-navigator__container sm:grid grid-cols-4 gap-4 content-center max-w-4xl ml-auto mr-auto mt-16 px-3 sm:px-0">
        {topics.map((topic: TopicItemProps) => (
          <TopicItem key={topic.title} title={topic.title} url={topic.url} icon={topic.icon} />
        ))}
      </div>
    </div>
  )
}

export default TopicNavigation
