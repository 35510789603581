import Icon from '@/components/Icon'
import { Link } from '../Link/Link'

export interface TopicItemProps {
  title: string
  url: string
  icon: string
}

const TopicItem = (props: TopicItemProps) => {
  return (
    <div className="topic-item rounded-xl w-full h-32 sm:h-0 pl-10 sm:pl-0 pt-10 sm:pt-0 mb-2 sm:mb-0 max-w-[345px] sm:w-28 sm:h-28 md:w-40 md:h-40 lg:w-48 lg:h-48 mx-auto cursor-pointer hover:bg-darkerBlack hover:text-purple active:text-purple">
      <Link href={props.url}>
        <div className="text-left sm:text-center">
          <Icon
            disableHover
            name={props.icon}
            className="cursor-pointer inline-block mb-[-17px] sm:mb-0 mr-6 sm:mr-0 w-12 h-12 sm:w-6 sm:h-6 sm:mt-8 sm:mb-2 md:w-10 md:h-10 md:mt-12 md:mb-3 lg:w-12 lg:h-12 lg:mt-14 lg:mb-5"
          />
          <p className="font-roboto text-lg sm:text-base md:text-lg font-bold uppercase inline-block sm:block">
            {props.title}
          </p>
        </div>
      </Link>
    </div>
  )
}

export default TopicItem
