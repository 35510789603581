import { useEffect, useRef, useState } from 'react'
import Plyr, { Source } from 'plyr'
import { Video as VideoEntity } from '@mpe/api-client/models/entities/video'
import apiClient from '@mpe/api-client'

import Loader from '../Loader'
import Icon from '../Icon'

import useDataLayer from '@/hooks/useDataLayer'

import 'plyr/dist/plyr.css'
import { useRouter } from 'next/router'
import { useDispatch, useStore } from 'react-redux'
import { setCurrentSegment } from '@/store/slices/videoPlayerSlice'
import { IVideoSegment } from '@mpe/api-client/models/entities/video'

interface VideoPlayerProps {
  entity: VideoEntity
  setVideoPlayer?: (player: Plyr) => void
  onPlay?: () => void
  autoplay?: boolean
  onComplete?: any
  embedded?: boolean
  startAt?: number
  classNames?: string
}

let playerReadyCounter = 0

function findNewSegment(
  currentTime: number,
  segments: IVideoSegment[],
  callback: (newSegment: number) => void
): void {
  // TODO: use of an interval tree may be overshot?
  for (const [k, s] of Object.entries(segments)) {
    const i = parseInt(k)
    const next = segments[i + 1]
    if (currentTime > s.startAt && currentTime < (next?.startAt || Infinity)) {
      callback(i + 1)
      break
    }
  }
}

function outOfSegment(currentTime: number, currentSegment: number, segments: IVideoSegment[]) {
  const current = segments[currentSegment - 1]
  const next = segments[currentSegment]
  return currentTime < current.startAt || currentTime > (next?.startAt || Infinity)
}

function updateUrl(entityQuery: string, newSegment: number) {
  const newUrl = `/${entityQuery}?segment=${newSegment}`
  window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl)
}

export default function VideoPlayer({
  entity,
  setVideoPlayer,
  onPlay,
  autoplay,
  onComplete,
  embedded,
  startAt,
  classNames
}: VideoPlayerProps) {
  const dataLayer = useDataLayer()
  const router = useRouter()

  const [videoSources, setVideoSources] = useState<Source[] | null>(null)
  const [loadingSign, setLoadingSign] = useState(true)
  const [firstPlay, setFirstPlay] = useState(true)
  const [logoOverlay, setLogoOverlay] = useState(embedded)

  const playerReadyTimeout = useRef<any>(null)

  const playerRef = useRef<Plyr | null>()

  const dispatch = useDispatch()

  const store = useStore()

  const populateVideoUrls = async () => {
    if ('completed' !== entity.convertStatus) {
      setVideoSources([
        {
          src: (entity as VideoEntity).getUrl(),
          type: 'video/mp4'
        }
      ])

      return
    }

    const response = await fetch(`/api/video-urls/${entity.id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })

    if (200 !== response.status) {
      // TODO: handle error
      return
    }

    const data = await response.json()
    if ('object' !== typeof data.data) {
      // TODO: handle error
      return
    }

    if (data.data.fallback !== undefined) {
      setVideoSources([
        {
          src: data.data.fallback.length
            ? data.data.fallback
            : apiClient.getConfig().s3Url + (entity as VideoEntity).videoName,
          type: 'video/mp4'
        }
      ])

      return
    }

    const validSizes = ['480p', '576p', '720p', '1080p']
    const sources: Source[] = []

    Object.keys(data.data)
      .filter((size: string) => validSizes.includes(size))
      .forEach((size: string) => {
        sources.push({
          src: data.data[size],
          size: Number(size.replace('p', '')),
          type: 'video/mp4'
        })
      })

    setVideoSources(sources)
  }

  useEffect(() => {
    populateVideoUrls()
  }, [entity])

  useEffect(() => {
    if (!videoSources) {
      return
    }

    const player: Plyr = new Plyr('#videoPlayer', {
      settings: ['quality'],
      controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen']
    })

    player.on('play', () => {
      dataLayer.push({
        event: 'play-video',
        entityId: entity.id,
        title: entity.name
      })
    })

    player.source = {
      type: 'video',
      sources: videoSources
    }

    player.quality = 720

    player.on('playing', () => {
      if (firstPlay && onPlay) {
        setFirstPlay(false)
        onPlay()
      }
    })

    player.on('loadeddata', () => {
      setLoadingSign(false)
    })

    if (setVideoPlayer) {
      setVideoPlayer(player)
    }

    // play if autoplay enabled
    // reason why autoplay option is not used:
    // the `complete` event will not trigger if autoplay option is set to true
    player.on('ready', (event) => {
      const instance = event.detail.plyr
      playerRef.current = instance

      // Reason behind this are the unnecessary renders of entity.
      // There should be one render on entity load, yet it is 3 or 4.
      // This should be investigated in the near future.
      // TODO
      clearInterval(playerReadyTimeout.current as any)
      playerReadyTimeout.current = setInterval(() => {
        if (player.duration === 0) {
          return
        }

        if (autoplay) {
          /*player.play()?.catch((error) => {
            console.log('Error while trying to autostart video', error)
          })*/
          // INFO: autostart temporarily disabled because of the index blog issue
          clearInterval(playerReadyTimeout.current as any)
        }

        if (startAt && embedded && player.duration > startAt) {
          player.currentTime = startAt
          clearInterval(playerReadyTimeout.current as any)
        }

        playerReadyCounter++
        if (playerReadyCounter > 100) {
          clearInterval(playerReadyTimeout.current as any)
        }
      }, 500)
    })

    player.on('ended', () => {
      if (onComplete) {
        onComplete()
      }
    })

    if (embedded) {
      player.on('play', () => {
        setLogoOverlay(false)
      })
      player.on('pause', () => {
        setLogoOverlay(true)
      })
      player.on('ended', () => {
        setLogoOverlay(true)
      })
    }

    if (entity?.segments?.length && !router.asPath.includes('/lejatszasi-listak/')) {
      const initialSegment = router.query.segment && parseInt(router.query.segment as string)

      dispatch(setCurrentSegment(initialSegment ?? 1))
      //let currentSegment = typeof initialSegment === 'number' ? initialSegment : 1

      if (initialSegment) {
        const onDataLoaded = () => {
          const seekTo = entity.segments[initialSegment - 1]?.startAt ?? null
          if (seekTo && seekTo <= player.duration) {
            player.off('loadeddata', onDataLoaded)
            player.currentTime = seekTo
          }
        }
        player.on('loadeddata', onDataLoaded)
      }

      player.on('timeupdate', (event) => {
        const currentTime = event.detail.plyr.currentTime
        const segments = entity.segments
        const currentSegment = store.getState().videoPlayer.currentSegment
        if (outOfSegment(currentTime, currentSegment, segments)) {
          findNewSegment(currentTime, segments, (newSegment) => {
            dispatch(setCurrentSegment(newSegment))
            updateUrl(router.query.entity as string, newSegment)
          })
        }
      })
    }

    return () => {
      if (!player) {
        return
      }

      player.destroy()
      clearInterval(playerReadyTimeout.current as any)
    }
  }, [videoSources, embedded])

  const wrapperClass =
    (embedded ? 'absolute w-full h-full with-embedded-plyr' : 'w-full md:w-5/6 2xl:w-full') +
    (classNames ? ' ' + classNames : '')

  const onLogoClick = () => {
    try {
      const player = playerRef.current
      player?.play()
    } catch (e) {}
  }

  return (
    <div className={wrapperClass}>
      {loadingSign && (
        <div className="absolute top-0 left-0 h-full w-full">
          <div className="relative flex h-full w-full items-center justify-center">
            <Loader />
          </div>
        </div>
      )}
      <video id="videoPlayer" className="w-full h-full plyr" />
      {!loadingSign && logoOverlay && (
        <div
          className="absolute left-0 top-0 w-full h-full bg-white/80 cursor-pointer flex justify-center items-center"
          style={{ height: 'calc(100% - 50px)' }}
          onClick={onLogoClick}
        >
          <Icon
            name="mpe-logo-color"
            className="w-48 max-w-full sm:w-80 filter drop-shadow-sm"
            style={{ filter: 'drop-shadow(1px 1px 1px black)' }}
          />
        </div>
      )}
    </div>
  )
}
