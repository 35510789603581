import { useRef, useState } from 'react'
import { useRouter } from 'next/router'
import ButtonModern from '@/components/Button/ButtonModern'
import Icon from '@/components/Icon'
import { Spinner } from '@/components/Spinner'

export const Search = () => {
  const router = useRouter()
  const inputRef = useRef(null)
  const [searching, setSearching] = useState(false)

  const gotoAndSearch = (search: any) => {
    if (searching || (!search && search !== '')) {
      return
    }

    setSearching(true)
    router.push('/kereses/entities?search=' + search.trim())
  }

  const onClick = () => {
    if (!searching && inputRef.current) {
      gotoAndSearch((inputRef.current as any).value)
    }
  }

  const onPressEnter = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') onClick()
  }

  return (
    <div className="search text-center mt-4 md:mt-20 lg:mt-32 pb-0 md:pb-12">
      <p className="font-gilroy font-extrabold xt-center mb-3 text-4xl lg:text-5xl mx-5">
        Keresd meg a kedvenceidet
      </p>
      <div className="pl-5 pr-5">
        <input
          ref={inputRef}
          onKeyPress={onPressEnter}
          type="text"
          className="focus:outline-none p-3 pl-5 pr-5 sm:mr-3 text-base font-medium leading-6 text-white w-full sm:w-[445px] md:w-[575px] lg:w-[625px] bg-black border rounded"
          placeholder="Írd be kedvenc előadódat (Pl.: Omega)"
          disabled={searching}
        />
        <ButtonModern className="w-full sm:w-auto" onClick={onClick}>
          {searching ? (
            <Spinner className="w-5 h-5 mb-[-5px] mr-3 inline-block -translate-y-1 -translate-x-1" />
          ) : (
            <Icon
              disableHover
              className="w-5 h-5 mb-[-5px] mr-3 inline-block"
              name="button-search"
            />
          )}
          <span className="align-middle">Keresés</span>
        </ButtonModern>
      </div>
    </div>
  )
}

export default Search
